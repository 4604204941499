<!--
 유해화학자재에 대한 입출고 관리하는 화면
  1. 유해화학자재란 화학자재 중 PSM 여부가 'Yes'인 경우
    ** 화관법에 따르면 유독물질, 허가물질, 제한물질 또는 금지물질, 사고대비물질, 그 밖에 유해성 또는 위해성이 있거나 그러할 우려가 있는 화학물질을 의미
  2. SAP에서 자재정보 및 입출고 관리를 하고 있지 않음으로 해당 화면에서 입출고를 관리함.(LNF)
    ** 기본적으로는 SAP과 I/F를 통하여 정보를 받음
    ** 그로인해 훗날 해당 화면이 아닌 SAP에서 받게 처리가 되어야 할 수도 있음(현재 scope 아님)
  3. 이전에는 유해화학물질이였음에도 목록에 표시될 수 있음
     구성물질등이 바뀜으로 자재의 규제사항이 달라질 수 있음으로
  4. 목록에는 다음 물질을 표시
   - 유해화학자재
   - 이전에 유해화학자재로 입출고 정보가 있는 자재
-->
<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            type="year"
            defaultStart="-2y"
            defaultEnd="0y"
            label="(기간)년도"
            name="f_years"
            v-model="searchParam.f_years"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="관리대장(제조/수입/사용/판매) 일괄업로드"
      :columns="gridColumns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="add" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'inout-management',
  data() {
    return {
      searchParam: {
        plantCd: null,
        f_years: [],
        years: [],
      },
      grid: {
        defaultColumns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'year',
            field: 'year',
            label: '년도',
            align: 'center',
            style: 'width:80px',
            sortable: true,
            type: 'link'
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridColumns() {
      let detault = this.$_.clone(this.grid.defaultColumns);
      for (let month = 1; month <= 12; month++) {
        detault.push({
          name: `month${month}`,
          field: `month${month}`,
          label: `${month}월`,
          child: [
            {
              name: `in${month}`,
              field: `in${month}`,
              label: '입고',
              align: 'right',
              style: 'width:60px',
              sortable: true,
              type: 'cost'
            },
            {
              name: `out${month}`,
              field: `out${month}`,
              label: '출고',
              align: 'right',
              style: 'width:60px',
              sortable: true,
              type: 'cost'
            },
            {
              name: `inventory${month}`,
              field: `inventory${month}`,
              label: '재고',
              align: 'right',
              style: 'width:60px',
              sortable: true,
              type: 'cost'
            },
          ]
        });
      }
      return detault;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.chm.inout.inoutstatus.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.f_years 
        && this.searchParam.f_years.length > 0 
        && this.searchParam.f_years[0]) {
        let from = this.$_.parseInt(this.searchParam.f_years[0]);
        let to = this.$_.parseInt(this.searchParam.f_years[1]);
        let years = [];
        for (let i = from; i <= to; i++) {
          years.push(i);
        }
        this.searchParam.years = years
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    add() {
      this.linkClick()
    },
    linkClick(row) {
      this.popupOptions.title = '관리대장(제조/수입/사용/판매) 엑셀업로드'; // 관리대장(제조/수입/사용/판매) 엑셀업로드
      this.popupOptions.param = {
        plantCd: row ? row.plantCd : this.$store.getters.user.plantCd,
        year: row ? row.year : '',
        isOld: row ? true : false,
      }
      this.popupOptions.target = () => import(`${'./inoutManagementUploadDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
